import { lazy, Suspense, useEffect, useState } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import PrivateRoute from './PrivateRoute';

export const IndexPage = lazy(() => import('../pages/app'));
export const ContactPage = lazy(() => import('../pages/contact'));
export const LoginPage = lazy(() => import('../pages/login'));
export const ResetPasswordPage = lazy(() => import('../pages/reset-password'));
export const ChangePasswordPage = lazy(() => import('../pages/change-password'));
export const RegisterPage = lazy(() => import('../pages/register'));

export const ExpensesPage = lazy(() => import('../pages/expenses'));
export const AddExpensesView = lazy(() => import('../pages/add-expenses'));
export const EditExpensesView = lazy(() => import('../pages/edit-expenses'));

export const IncomesPage = lazy(() => import('../pages/incomes'));
export const AddIncomesView = lazy(() => import('../pages/add-incomes'));
export const EditIncomesPage = lazy(() => import('../pages/edit-incomes'));

export const WarehousePage = lazy(() => import('../pages/warehouse'));

export const ProfileSettingsPage = lazy(() => import('../pages/profile-settings'));
export const CompanySettingsPage = lazy(() => import('../pages/company-settings'));
export const BankAccountPage = lazy(() => import('../pages/bank-accounts'))
export const SubscriptionPage = lazy(() => import('../pages/subscription-settings'));

export const ImportExportMoneyPage = lazy(() => import('../pages/import-export-money'));

export const InsurancePage = lazy(() => import('../pages/insurance'));

export const UploadPage = lazy(() => import('../pages/upload'));

export const Page404 = lazy(() => import('../pages/page-not-found'));

export const AdminDashboardPage = lazy(() => import('../pages/admin/dashboard'));
export const AdminCompaniesPage = lazy(() => import('../pages/admin/companies'));
export const AdminCompaniesSinglePage = lazy(() => import('../pages/admin/companiesSingle'));
export const InvoicePage = lazy(() => import('../pages/admin/invoices'));
export const ExpensePage = lazy(() => import('../pages/admin/expenses'));
// ----------------------------------------------------------------------

export default function Router() {

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <PrivateRoute
          element={
            <Outlet />
          }
        />
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'expenses', element: <ExpensesPage /> },
        { path: 'add-expenses', element: <AddExpensesView /> },
        { path: 'edit-expenses/:id', element: <EditExpensesView /> },
        { path: 'incomes', element: <IncomesPage /> },
        { path: 'add-incomes', element: <AddIncomesView /> },
        { path: 'edit-incomes/:id', element: <EditIncomesPage /> },
        { path: 'warehouse', element: <WarehousePage /> },
        { path: 'profile-settings', element: <ProfileSettingsPage /> },
        { path: 'company-settings', element: <CompanySettingsPage /> },
        { path: 'bank-accounts', element: <BankAccountPage /> },
        { path: 'subscription', element: <SubscriptionPage /> },
        { path: 'social-insurance', element: <InsurancePage /> },
        { path: 'import-export-money', element: <ImportExportMoneyPage /> },
        { path: 'upload', element: <UploadPage /> },
      ],
    },
    {
      path: '/admin',
      element: <PrivateRoute isAdmin={true} />,
      children: [
        { element: <AdminDashboardPage />, index: true },
        { path: 'companies', element: <AdminCompaniesPage /> },
        { path: 'companies/:id', element: <AdminCompaniesSinglePage /> },
        { path: 'invoices/:id', element: <InvoicePage /> },
        { path: 'expenses/:id', element: <ExpensePage /> },
      ]
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: 'change-password',
      element: <ChangePasswordPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
