import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    bankAccounts: [],
    loading: false,
    error: null
};

export const addBankAccount = createAsyncThunk(
    'users/addBankAccount',
    async ({ iban, bank_name, bic, company_id }, { rejectWithValue }) => {
        try {
            const response = await api.post('/bank-accounts/add-bank-account', { iban, bank_name, bic, company_id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getBankAccounts = createAsyncThunk(
    'users/getBankAccounts',
    async ({ company_id }, { rejectWithValue }) => {
        try {
            const response = await api.post('/bank-accounts/get-bank-accounts', { company_id });
            return response.data.bank_accounts;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const deleteBankAccount = createAsyncThunk(
    'users/deleteBankAccount',
    async ({ id }, { rejectWithValue }) => {
        console.log(id);
        try {
            const response = await api.post('/bank-accounts/delete-bank-account', { id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

const bankAccountsSlice = createSlice({
    name: 'bankAccounts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addBankAccount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addBankAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.bankAccounts = action.payload;
            })
            .addCase(addBankAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getBankAccounts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getBankAccounts.fulfilled, (state, action) => {
                state.loading = false;
                state.bankAccounts = action.payload;
            })
            .addCase(getBankAccounts.rejected, (state, action) => {
                state.loading = false;
                state.bankAccounts = [];
                state.error = action.payload;
            })
            .addCase(deleteBankAccount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteBankAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.bankAccounts = state.bankAccounts.filter(bankAccount => bankAccount.id !== action.payload.id);
            })
            .addCase(deleteBankAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default bankAccountsSlice.reducer;